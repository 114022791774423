import React from 'react';
import TextField from '@mui/material/TextField';
import TitanDialog from '../Titan/TitanDialog';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import CustomFields from '../CustomFields/CustomFields';

export default function FinalPartTypeDialog({
  finalPartType,
  onClose,
  onSave,
  hasFinalParts,
}) {
  const [name, setName] = React.useState(
    finalPartType ? finalPartType.name : '',
  );
  const [labels, setLabels] = React.useState(
    finalPartType && finalPartType.labels && finalPartType.labels.length
      ? finalPartType.labels.split(',')
      : [],
  );
  const [openPreFusionDialog, setOpenPreFusionDialog] = React.useState(false);
  const [openPostFusionDialog, setOpenPostFusionDialog] = React.useState(false);

  const [preFusionCustomFields, setPreFusionCustomFields] = React.useState([]);
  const [postFusionCustomFields, setPostFusionCustomFields] = React.useState(
    [],
  );
  const [customFields, setCustomFields] = React.useState(
    finalPartType && finalPartType.customFields
      ? [...finalPartType.customFields].sort((a, b) => a.order - b.order)
      : [],
  );

  const onSubmitForm = () => {
    return onSave({
      name,
      labels: labels.join(', '),
      customFields: customFields.map(
        ({
          id,
          name,
          formType,
          type,
          units,
          options,
          expectedValue,
          minValue,
          maxValue,
          image,
          isNew,
          newImage,
        }) => {
          const customField = {
            name,
            formType,
            type,
            units,
            options,
            expectedValue,
            minValue: minValue ? String(minValue) : null,
            maxValue: maxValue ? String(maxValue) : null,
            image,
          };

          if (image === null) {
            customField.newImage = null;
          }

          if (newImage !== undefined) {
            customField.newImage = newImage;
          }

          if (!isNew) {
            customField.id = id;
          }

          return customField;
        },
      ),
    });
  };

  const onAddPreFusionCustomField = React.useCallback(
    async (newCustomField) => {
      setCustomFields((prev) => [
        ...prev,
        { ...newCustomField, formType: 'PRE_FUSION' },
      ]);
    },
    [],
  );

  const onAddPostFusionCustomField = React.useCallback(
    async (newCustomField) => {
      setCustomFields((prev) => [
        ...prev,
        { ...newCustomField, formType: 'POST_FUSION' },
      ]);
    },
    [],
  );

  const onChangePreFusionCustomField = React.useCallback(
    async (updatedCustomField) => {
      try {
        setCustomFields((prev) =>
          prev.map((row) =>
            row.id === updatedCustomField.id
              ? { ...updatedCustomField, formType: 'PRE_FUSION' }
              : row,
          ),
        );
      } catch (error) {
        setCustomFields((prev) => [...prev]);
      }
    },
    [],
  );

  const onChangePostFusionCustomField = React.useCallback(
    async (updatedCustomField) => {
      try {
        setCustomFields((prev) =>
          prev.map((row) =>
            row.id === updatedCustomField.id
              ? { ...updatedCustomField, formType: 'POST_FUSION' }
              : row,
          ),
        );
      } catch (error) {
        setCustomFields((prev) => [...prev]);
      }
    },
    [],
  );

  const onDeleteCustomField = React.useCallback((customField) => {
    setCustomFields((prev) => prev.filter((cf) => cf.id !== customField.id));
  }, []);

  const customTypes = React.useMemo(() => {
    return [
      {
        type: 'MACHINE_RELATED_NC_SELECT',
        baseType: 'SINGLE_SELECT',
        typeLabel: 'Machine related NC',
        options: [
          'OK Quality',
          'Error message',
          'Clogged tape',
          'Clogged polymer',
          'Adhesion issue',
          'Cancelled',
          'Other',
        ],
      },
      {
        type: 'PART_RELATED_NC_SELECT',
        baseType: 'SINGLE_SELECT',
        typeLabel: 'Part related NC',
        options: [
          'OK Quality',
          'Tape sticking out',
          'Tape missing',
          'Polymer missing',
          'Delamination',
          'Layer shift',
          'Other',
        ],
      },
      {
        type: 'FIBER_MATERIAL_NC_SELECT',
        baseType: 'SINGLE_SELECT',
        typeLabel: 'Tape material NC',
        options: [
          'OK Quality',
          'Winding',
          'Broken',
          'Empty',
          'Wrong material',
          'Other',
        ],
      },
      {
        type: 'PRIME_TOWEL_SELECT',
        baseType: 'SINGLE_SELECT',
        typeLabel: 'Prime tower',
        options: ['OK Quality', 'Bad Quality'],
      },
    ];
  }, []);

  React.useEffect(() => {
    console.log({ customFields });

    setPreFusionCustomFields(
      customFields.filter(
        (customField) => customField.formType === 'PRE_FUSION',
      ),
    );
    setPostFusionCustomFields(
      customFields.filter(
        (customField) => customField.formType === 'POST_FUSION',
      ),
    );
  }, [customFields]);

  return (
    <TitanDialog
      title={`${
        finalPartType && finalPartType.id ? 'Edit' : 'Create'
      } Final Part Type`}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      onSave={onSubmitForm}
      isValid={
        name && customFields.every((cf) => cf.name && cf.name.length !== 0)
      }
    >
      <TextField
        autoFocus
        id="name"
        label="name"
        type="text"
        value={name}
        fullWidth
        margin="normal"
        variant="outlined"
        onChange={(e) => setName(e.target.value)}
      />

      <Autocomplete
        multiple
        options={[]}
        freeSolo
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Labels"
            margin="normal"
            variant="outlined"
            helperText="Type your label and press Enter"
          />
        )}
        value={labels}
        onChange={(event, value) => setLabels(value)}
        onBlur={(event) => {
          if (event.target.value.trim() !== '') {
            setLabels((prev) => [...prev, event.target.value]);
          }
        }}
        clearOnBlur
      />

      <Box sx={{ mt: 2 }}>
        <CustomFields
          title="Pre-Fusion"
          customFields={preFusionCustomFields}
          onAdd={onAddPreFusionCustomField}
          onChange={onChangePreFusionCustomField}
          onDelete={onDeleteCustomField}
          disableEdit={hasFinalParts}
          disableEditTooltip={
            hasFinalParts
              ? "You can't add a new custom field because the Fusion Plan has final parts"
              : ''
          }
          disableDelete={hasFinalParts}
          disableDeleteTooltip={
            hasFinalParts
              ? "You can't delete custom field because the Fusion Plan has final parts"
              : ''
          }
          openDialog={openPreFusionDialog}
          setOpenDialog={setOpenPreFusionDialog}
          customTypes={customTypes}
        />
      </Box>

      <Box sx={{ mt: 2 }}>
        <CustomFields
          title="Post-Fusion"
          customFields={postFusionCustomFields}
          onAdd={onAddPostFusionCustomField}
          onChange={onChangePostFusionCustomField}
          onDelete={onDeleteCustomField}
          disableEdit={hasFinalParts}
          disableEditTooltip={
            hasFinalParts
              ? "You can't add a new custom field because the Fusion Plan has final parts"
              : ''
          }
          disableDelete={hasFinalParts}
          disableDeleteTooltip={
            hasFinalParts
              ? "You can't delete custom field because the Fusion Plan has final parts"
              : ''
          }
          openDialog={openPostFusionDialog}
          setOpenDialog={setOpenPostFusionDialog}
          customTypes={customTypes}
        />
      </Box>
    </TitanDialog>
  );
}
